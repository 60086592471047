import React from "react";
import styled, { useTheme } from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  WaitingRoomToggleButton,
  VideoToggleButton,
  AudioToggleButton,
  ExitButton,
  ChatButton,
  SettingsButton,
} from "./Buttons";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";
import { appInstanceId } from "../../App";

const ControlWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding-bottom: 15px;
  transition: 0.2s ease-in-out;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    align-items: normal;
    padding: 0 10px 20px 10px;
    width: calc(100% - 20px);
  }
`;

const ControlGroup = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;

  &:first-of-type:not(:last-of-type) {
    justify-content: flex-start;
  }

  &:last-of-type:not(:first-of-type) {
    justify-content: flex-end;
  }
`;

const ControlItem = styled.div`
  margin: 0 5px;
  flex: 0 0 auto;
  width: 45px;
  height: 45px;
  &:hover {
    border-radius: 50%;
    box-shadow: 0px 0px 48px 0px rgba(255, 255, 255, 0.48);
    backdrop-filter: blur(12px);
  }

  @media (min-width: 400px) {
    margin: 0 10px;
    width: 48px;
    height: 48px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 56px;
    height: 56px;
  }
`;

function SessionControls({
  isTherapist,
  setExitConfirmationOpen,
  waitingRoomOpen,
  setWaitingRoomOpen,
  settingsOpen,
  setSettingsOpen,
}) {
  const theme = useTheme();
  const { analyticsEvent } = useAnalyticsContext();
  const xs = !useMediaQuery(`(min-width:${theme.breakpoints.tablet})`);

  const exitHandler = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "hangup",
    });

    setExitConfirmationOpen(true);
  };

  return (
    <ControlWrap>
      <ControlGroup>
        {isTherapist && (
          <ControlItem>
            <WaitingRoomToggleButton
              waitingRoomOpen={waitingRoomOpen}
              setWaitingRoomOpen={setWaitingRoomOpen}
            />
          </ControlItem>
        )}
        {xs && (
          <>
            <ControlItem>
              <ChatButton channel={appInstanceId} />
            </ControlItem>
          </>
        )}
      </ControlGroup>
      <ControlGroup>
        <ControlItem>
          <AudioToggleButton />
        </ControlItem>
        <ControlItem>
          <VideoToggleButton />
        </ControlItem>
        <ControlItem>
          <ExitButton onClick={exitHandler} />
        </ControlItem>
        {xs && (
          <>
            <ControlItem>
              <SettingsButton
                settingsOpen={settingsOpen}
                setSettingsOpen={setSettingsOpen}
              />
            </ControlItem>
          </>
        )}
      </ControlGroup>
      {!xs && (
        <ControlGroup>
          <ControlItem>
            <ChatButton channel={appInstanceId} />
          </ControlItem>
          <ControlItem>
            <SettingsButton
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpen}
            />
          </ControlItem>
        </ControlGroup>
      )}
    </ControlWrap>
  );
}

export default SessionControls;
