import { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button } from "@sussex/react-kit/elements";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { useAudioAlertContext } from "../../providers/AudioAlertProvider";
import useCopy from "../../hooks/useCopy";
import Panel from "../Panel";
import WaitingRoomBackground from "../WaitingRoomBackground";
import WaitingRoomShare from "../WaitingRoomShare";
import InviteToSessionModal from "../InviteToSessionModal";
import NativeAppBanner from "../NativeAppBanner";
import ClientList from "./ClientList";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const PanelWrapper = styled(Panel)`
  & > * {
    padding: 24px 16px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    & > * {
      padding: 0;
    }
  }
`;

const Content = styled.div`
  flex-direction: row;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    min-height: 448px;
  }
`;

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: calc(50% - 32px);
    padding: 16px 0;
    margin: 0 auto;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
    padding: 10px 0;
  }
`;

const ClientListWrapper = styled.div`
  margin-bottom: 8px;
`;

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 21px;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  margin: 0 0 8px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 26px;
    margin: 0;
  }
`;

const Subtitle = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

const StartButton = styled(Button)`
  width: 100%;
  border-radius: 100px;
  height: 56px;
  margin: 16px 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 0 0 40px;
  }
`;

const Titles = () => {
  const [title, subtitle] = useCopy(["waitingRoom.title", "dashboard.title"]);
  return (
    <TitleWrapper>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </TitleWrapper>
  );
};

const TherapistWaitingRoom = ({ onStart }) => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const {
    room: { therapistDisplayName, therapistPhotoUrl },
  } = useWaitingRoomContext();
  const {
    state: { waitingRoom, session },
  } = useAppStateContext();
  const [startText] = useCopy(["dashboard.startEmpty"]);

  const theme = useTheme();
  const xs = !useMediaQuery(`(min-width:${theme.breakpoints.tablet})`);

  const { setChimeOnNewParticipants } = useAudioAlertContext();

  useEffect(() => {
    setChimeOnNewParticipants(true);
    return () => {
      setChimeOnNewParticipants(false);
    };
  }, [setChimeOnNewParticipants]);

  return (
    <Wrapper>
      {session.ended ? <NativeAppBanner therapist /> : null}
      <PanelWrapper>
        <Content>
          {xs ? <Titles /> : null}
          <ImageWrapper>
            <WaitingRoomBackground
              photo={therapistPhotoUrl}
              name={therapistDisplayName}
            />
          </ImageWrapper>
          <ActionWrapper>
            {!xs && <Titles />}
            <ClientListWrapper>
              <ClientList clients={waitingRoom.participants} />
            </ClientListWrapper>
            <StartButton onClick={onStart}>{startText}</StartButton>
            <WaitingRoomShare onSendInvite={() => setShowInviteModal(true)} />
          </ActionWrapper>
        </Content>
      </PanelWrapper>
      {showInviteModal && (
        <InviteToSessionModal close={() => setShowInviteModal(false)} />
      )}
    </Wrapper>
  );
};

export default TherapistWaitingRoom;
